import {
  GetRequestInterface,
  IdAndName,
  RequestInterfaceNew,
  TableRequestInterface,
} from '@src/interfaces'
import { useSelector } from 'react-redux'
import { api, apiWithoutHandling, normalizeCaptchaError } from '@src/api/index'
import { API } from '@src/constants/api'
import {
  TenantAdminInterface,
  TenantDiscountInterface,
  TenantInterface,
  TenantResponseInterface,
  TenantSubscriptionPlansInterface,
} from '@src/interfaces/tenants'
import { useFetch, useUpdate } from '@src/utils/reactQuery'
import {
  SubscriptionInfoInterface,
  SubscriptionInvoiceInterface,
} from '@src/interfaces/plans'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { FilterByInterface } from '@src/interfaces/data'
import { TenantOnboardingCheckpointInterface } from '@src/interfaces/onboardingChecklist'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

export const tenantsAdminRequests: RequestInterfaceNew<TenantAdminInterface> = {
  get: async ({ id }) => api.get(`${API.TENANTS_ADMIN}/${id}`, undefined, 'v2'),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.TENANTS_ADMIN}/${id}`, data, undefined, 'v2'),
  submit: async data =>
    apiWithoutHandling
      .post(API.TENANTS_ADMIN, data, undefined, 'v2')
      .catch(normalizeCaptchaError),
}

export const tenantsRequests: RequestInterfaceNew<TenantInterface> = {
  get: async ({ id }) => api.get(`${API.TENANTS}/${id}`, undefined, 'v2'),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.TENANTS}/${id}`, data, undefined, 'v2'),
  submit: async data => apiWithoutHandling.post(API.TENANTS, data, undefined, 'v2'),
}

export const useGetTenant = (id?: string) =>
  useFetch<TenantResponseInterface>(
    id ? `${API.TENANTS}/${id}` : null,
    'v2',
    undefined,
    undefined,
    {
      refetchInterval: 5000,
    },
  )

export const useCloseAccount = (id?: string) =>
  useUpdate(`${API.TENANTS}/${id}/closeAccount`, undefined, undefined, true)

export const tenantInvoicesRequests = (
  id: string,
): TableRequestInterface<SubscriptionInvoiceInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.TENANTS}/${id}/subscriptions/invoices`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const useTenantDiscounts = (id: string) => {
  const result = useFetch<GetRequestInterface<TenantDiscountInterface>>(
    `${API.TENANTS}/${id}/discounts`,
  )
  return { discounts: result?.data?.results, ...result }
}

export const useTenantSubscriptionPlans = (id: string) => {
  const result = useFetch<GetRequestInterface<TenantSubscriptionPlansInterface>>(
    `${API.TENANTS}/${id}/availableSubscriptionPlans`,
  )
  return {
    subscriptionPlans: result?.data?.results.map(p => p.subscription_plan),
    ...result,
  }
}

export const deleteTenantDiscount = (id: string, discountId: number) =>
  apiWithoutHandling.delete(`${API.TENANTS}/${id}/discounts/${discountId}`)

export const tenantDiscountsFormRequests: RequestInterfaceNew<TenantDiscountInterface> = {
  get: async ({ id, tenantId }) => api.get(`${API.TENANTS}/${tenantId}/discounts/${id}`),
  update: async (data, { id, tenantId }) =>
    apiWithoutHandling.patch(`${API.TENANTS}/${tenantId}/discounts/${id}`, data),
  submit: async (data, { tenantId }) =>
    apiWithoutHandling.post(`${API.TENANTS}/${tenantId}/discounts`, data),
}

export const downloadTenantInvoice = async (
  id: string | number,
  invoiceId: string | number,
) => {
  const res = await api.get<Blob>(
    `${API.TENANTS}/${id}/subscriptions/invoices/${invoiceId}/download`,
    {
      responseType: 'blob',
    },
  )
  const fileName = res.headers['content-disposition']
    ?.split('filename=')?.[1]
    ?.replace(/['"]/g, '')

  return { file: URL.createObjectURL(res.data), fileName: fileName || 'invoice.pdf' }
}

export const useTenantInvoiceDetails = (
  id: string | number,
  invoiceId: string | number,
) =>
  useFetch<SubscriptionInvoiceInterface>(
    `${API.TENANTS}/${id}/subscriptions/invoices/${invoiceId}`,
    undefined,
    undefined,
    true,
    {
      refetchOnWindowFocus: false,
    },
  )

export const useTenantSubscriptionInfo = (id: string | number) =>
  useFetch<SubscriptionInfoInterface>(`${API.TENANTS}/${id}/subscriptions/info`)

export const useTenantOnboardingProgress = (id: string | number) => {
  const response = useFetch<GetRequestInterface<TenantOnboardingCheckpointInterface>>(
    `${API.TENANTS}/${id}/onboarding`,
  )
  return response.data?.results
}
export const setTenantFeatures = (
  tenants: { id: string | number }[],
  features: IdAndName<string>[],
  mode: 'enable' | 'disable',
) => apiWithoutHandling.post(`${API.TENANTS}/${mode}Features`, { tenants, features })

export const useTenantSelector = (filters: FilterByInterface[], enabled = true) =>
  useFetch<{ options: IdAndName<string>[] }>(
    `${API.TENANTS}/selector`,
    undefined,
    { params: filterSortPageIntoQuery(undefined, filters) },
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled,
    },
  )

export const useCurrentTenantInfo = () => {
  const featureFlags = useSelector(selectFeatureFlags)

  const result = useFetch<{ state: 'demo' | 'active' }>(
    `${API.TENANTS}/currentTenantInfo`,
    undefined,
    undefined,
    true,
    {
      enabled: featureFlags.includes(FeatureFlags.CommercialProduct),
    },
  )

  return result.data
}

export const retryDemoModeSetup = (tenantId: number | string) =>
  api.put(`${API.TENANTS}/${tenantId}/retryDemoModeSetup`)

export const activateFromDemoMode = (tenantId: number | string) =>
  api.put(`${API.TENANTS}/${tenantId}/activateFromDemoMode`)
