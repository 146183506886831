import React from 'react'
import { Box, Layout } from '@revolut/ui-kit'

import Sidebar from './SideBar/SideBar'
import { PinnedAppsProvider } from '@src/pages/Hub/Apps/common'
import { PinnedTabsProvider } from '@src/features/TabPinning/pinnedTabs'
import { DemoModeWarningBar } from '@src/features/DemoMode/DemoModeWarningBar'

export const MainLayout: React.FC = ({ children }) => {
  return (
    <Box width="100%">
      <DemoModeWarningBar />
      <PinnedAppsProvider>
        <PinnedTabsProvider>
          <Layout variant="container(wide) main(wide) side(wide)">
            <Layout.Menu>
              <Sidebar />
            </Layout.Menu>
            <Layout.Main style={{ isolation: 'isolate' }}>{children}</Layout.Main>
            <Layout.Side />
          </Layout>
        </PinnedTabsProvider>
      </PinnedAppsProvider>
    </Box>
  )
}
