import React from 'react'
import { Relative, Token } from '@revolut/ui-kit'

import { useCurrentTenantInfo } from '@src/api/tenants'

export const DemoModeWarningBar = () => {
  const tenatnInfo = useCurrentTenantInfo()

  if (tenatnInfo?.state !== 'demo') {
    return null
  }

  return (
    <Relative
      top={0}
      left={0}
      right={0}
      height={40}
      bg={Token.color.accent}
      color={Token.color.white}
      display="flex"
      style={{ alignItems: 'center', justifyContent: 'center' }}
    >
      Demo mode is on
    </Relative>
  )
}
