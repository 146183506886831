import React from 'react'
import { Location } from 'history'
import { HubAppTabInterface, HubAppType, HubInterface } from '@src/interfaces/hub'
import { ROUTES } from '@src/constants/routes'
import {
  FeatureFlags,
  GlobalSettings,
  PerformanceSettings,
  PermissionTypes,
} from '@src/store/auth/types'
import Requests from '@src/pages/People/PeopleSubTabs/TimeOffPage/Requests'
import Policies from '@src/pages/People/PeopleSubTabs/TimeOffPage/Policies'
import UsersTable from '@src/pages/Admin/AccessManagement/Users/UsersTable'
import GroupsTable from '@src/pages/Admin/AccessManagement/Groups/GroupsTable'
import PermissionsTable from '@src/pages/Admin/AccessManagement/Permissions/PermissionsTable'
import NotificationTable from '@src/pages/People/PeopleSubTabs/Notifications/NotificationsTable'
import { CommunicationGroupsTable } from '@src/pages/People/PeopleSubTabs/CommunicationGroups/CommunicationGroupsTable'
import { EmployeeEmailTemplates } from '@src/pages/People/PeopleSubTabs/EmployeeEmailTemplates/EmployeeEmailTemplates'
import KPIsTable from '@src/pages/Performance/KPIs/KPIsTable'
import Templates from '@src/pages/Forms/KpiTemplates/Templates'
import Rules from '@src/pages/Forms/KpiTemplates/Rules'
import ReviewCyclesTable from '@src/pages/Performance/Review Cycles/ReviewCyclesTable'
import EmployeeTable from '@src/pages/People/PeopleSubTabs/Employees/EmployeeTable'
import SenioritiesTable from '@src/pages/People/PeopleSubTabs/Seniorities/SenioritiesTable'
import DynamicGroupsTable from '@src/pages/People/PeopleSubTabs/Dynamic Groups/DynamicGroups'
import Scheduler from '@src/pages/People/PeopleSubTabs/Attendance/Scheduler'
import ShiftSummary from '@src/pages/People/PeopleSubTabs/Attendance/ShiftSummary'
import ShiftBreakdown from '@src/pages/People/PeopleSubTabs/Attendance/ShiftBreakdown'
import AttendancePolicies from '@src/pages/People/PeopleSubTabs/Attendance/Policies'
import Benchmarks from '@src/pages/People/PeopleSubTabs/Compensation/Benchmarks'
import { Bonuses } from '@src/pages/People/PeopleSubTabs/Compensation/Bonuses'
import { AllDocuments } from '@src/pages/People/PeopleSubTabs/Documents/AllDocuments'
import DocumentCategories from '@src/pages/People/PeopleSubTabs/Documents/Categories'
import DocumentTemplates from '@src/pages/People/PeopleSubTabs/Documents/Templates'
import DocumentRules from '@src/pages/People/PeopleSubTabs/Documents/Rules'
import BenefitEnrolment from '@src/pages/People/PeopleSubTabs/Benefits/Enrolment'
import BenefitTemplates from '@src/pages/People/PeopleSubTabs/Benefits/Templates'
import { DriversTable } from '@src/apps/People/Engagement/DriversTable/DriversTable'
import { QuestionsTable } from '@src/apps/People/Engagement/QuestionsTable/QuestionsTable'
import { SurveysTable } from '@src/apps/People/Engagement/SurveysTable/SurveysTable'
import CommonCandidatesTable from '@src/features/CommonCandidatesTable/CommonCandidatesTable'
import Interviews from '@src/apps/Recruitment/Candidates/Interviews/Interviews'
import DataRetentionSettingsTable from '@src/pages/Recruitment/RecruitmentSubTabs/DataRetentionSettings/DataRetentionSettingsTable'
import EmailTemplatesTable from '@src/pages/Recruitment/RecruitmentSubTabs/Email Templates/EmailTemplatesTable'
import ReferralBonusTable from '@src/pages/Recruitment/RecruitmentSubTabs/Referral bonus/ReferralBonusTable'
import RecruitmentGroupsTable from '@src/pages/Recruitment/RecruitmentSubTabs/Recruitment groups/RecruitmentGroupsTable'
import HiringTable from '@src/pages/Recruitment/RecruitmentSubTabs/Hiring/HiringTable'
import InterviewScorecardTemplatesTable from '@src/pages/Recruitment/RecruitmentSubTabs/InterviewScorecardTemplates/InterviewScorecardTemplatesTable'
import OffersTable from '@src/pages/Recruitment/RecruitmentSubTabs/OfferForms/OffersTable/OffersTable'
import OfferPlaceholders from '@src/pages/Recruitment/RecruitmentSubTabs/OfferForms/OfferPlaceholders/OfferPlaceholders'
import OfferTemplates from '@src/pages/Recruitment/RecruitmentSubTabs/OfferForms/OfferTemplates/OfferTemplates'
import TeamsTable from '@src/pages/Organisation/OrganisationSubTabs/Teams/TeamsTable'
import DepartmentsTable from '@src/pages/Organisation/OrganisationSubTabs/Departments/DepartmentsTable'
import SpecialisationsTable from '@src/pages/Organisation/OrganisationSubTabs/Specialisations/SpecialisationsTable'
import RolesTable from '@src/pages/Organisation/OrganisationSubTabs/Roles/RolesTable'
import FunctionsTable from '@src/pages/Organisation/OrganisationSubTabs/Functions/FunctionsTable'
import { HomeSectionId } from '@src/interfaces/tabPinning'
import SupportToolWhitelist from '@src/pages/Performance/SupportTool/Whitelist'
import SupportToolGradeCalibration from '@src/pages/Performance/SupportTool/GradeCalibration'
import SupportToolEligibleEmployees from '@src/pages/Performance/SupportTool/EligibleEmployees'
import EmploymentPoliciesTable from '@src/apps/General/DataRetention/EmploymentPoliciesTable'
import RecruitmentPoliciesTable from '@src/apps/General/DataRetention/RecruitmentPoliciesTable'
import SkillsTable from '@src/pages/Performance/Skills/SkillsTable'
import CultureValuesTable from '@src/pages/Performance/CultureValues/CultureValuesTable'
import { ContractsTable } from '@src/pages/People/PeopleSubTabs/Contracts/ContractsTable'
import OnboardingEmployeesTable from '@src/pages/People/PeopleSubTabs/OnboardingEmployees/OnboardingEmployeesTable'
import ProbationEmployeesTable from '@src/pages/People/PeopleSubTabs/ProbationEmployees/ProbationEmployeesTable'
import ProbationTemplatesTable from '@src/pages/People/PeopleSubTabs/ProbationEmployees/ProbationTemplatesTable'
import OffboardingTable from '@src/pages/People/PeopleSubTabs/Offboarding/OffboardingTable'
import ResignationsTable from '@src/pages/People/PeopleSubTabs/Resignations/ResignationsTable'
// import { ContractTypes } from '@src/pages/People/PeopleSubTabs/Contracts/ContractTypes'
import { PayCyclesTable } from '@src/apps/People/Payroll/PayCyclesTable/PayCyclesTable'
import { commonDashboardSubtabs } from '@src/apps/General/Todo/common'
import RequisitionsTable from '@src/pages/Recruitment/RecruitmentSubTabs/Requisitions/RequisitionsTable'
import JobPostingsTable from '@src/pages/Recruitment/RecruitmentSubTabs/Job Postings/JobPostingsTable'
import LocationsTable from '@src/pages/People/PeopleSubTabs/Locations/LocationsTable'
import EntitiesTable from '@src/pages/People/PeopleSubTabs/Entities/EntitiesTable'
import PromotionNomineesTable from '@src/pages/Performance/PromotionNominees/PromotionNominees'
import FeedbackTable from '@src/pages/Admin/AdminSideBars/Feedback/FeedbackTable'
import { RoadmapsMain } from '@src/pages/Forms/RoadmapsMain/RoadmapsMain'
import {
  DocumentsSettingsInterface,
  EmployeeSettingsInterface,
  PerformanceSettingsInterface,
} from '@src/interfaces/settings'
import { ScreeningsTable } from '@src/apps/People/Screening/ScreeningsTable'
import { PaymentsTable } from '@src/apps/People/Payroll/PaymentsTable/PaymentsTable'
import { ReportsTable } from '@src/apps/General/DataAnalytics/ReportsTable'
import { QueriesTable } from '@src/apps/General/DataAnalytics/QueriesTable'
import { ConnectionsTable } from '@src/apps/General/DataAnalytics/ConnectionsTable'
import { KeyPersonsResponsibilitiesTable } from '@src/apps/People/KeyPersons/ResponsibilitiesTable'
import { KeyPersonsResponsibilityAssignmentsTable } from '@src/apps/People/KeyPersons/ResponsibilityAssignmentsTable/ResponsibilityAssignmentsTable'
import { KeyPersonsSuccessionPlansTable } from '@src/apps/People/KeyPersons/SuccessionPlansTable/SuccessionPlansTable'
import { HelpCenterFaqTable } from '@src/apps/General/HelpCenter/FaqTable/FaqTable'
import { HelpCenterFaqTopicsTable } from '@src/apps/General/HelpCenter/FaqTopicsTable/FaqTopicsTable'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'
import { FaqSDCategoriesTable } from '@src/apps/General/HelpCenter/FaqSDCategoriesTable/FaqSDCategoriesTable'
import { DocumentsBulkRequests } from '@src/pages/People/PeopleSubTabs/Documents/BulkRequests'
import { DocumentsCollections } from '@src/pages/People/PeopleSubTabs/Documents/Collections'

export type AppKey = keyof typeof appConfig & string

function createApplicationsMap<T extends { [name in AppKey]: HubAppType }>(cfg: T) {
  return cfg
}

export const colors = {
  teal: '#13d1a3',
  blue: '#0A84FF',
  deepPurple: '#805CFF',
}

const appConfig = {
  accessManagement: {
    id: 'accessManagement' as const,
    title: 'Access Management',
    image: 'access-management',
    bg: 'deep-grey',
    settingsConfig: settingsConfig.accessManagement,
    tabs: [
      {
        homeSectionId: HomeSectionId.accessManagementUsers,
        title: 'Users',
        homeSectionTitle: 'User access',
        path: ROUTES.ADMIN.ACCESS_MANAGEMENT.USERS,
        url: ROUTES.ADMIN.ACCESS_MANAGEMENT.USERS,
        canView: [PermissionTypes.ViewUser],
        component: UsersTable,
      },
      {
        homeSectionId: HomeSectionId.accessManagementGroups,
        title: 'Groups',
        homeSectionTitle: 'Access groups',
        path: ROUTES.ADMIN.ACCESS_MANAGEMENT.GROUPS,
        url: ROUTES.ADMIN.ACCESS_MANAGEMENT.GROUPS,
        canView: [PermissionTypes.ViewGroup],
        component: GroupsTable,
      },
      {
        homeSectionId: HomeSectionId.accessManagementPermissions,
        title: 'Permissions',
        path: ROUTES.ADMIN.ACCESS_MANAGEMENT.PERMISSIONS,
        url: ROUTES.ADMIN.ACCESS_MANAGEMENT.PERMISSIONS,
        canView: [PermissionTypes.ViewPermissions],
        component: PermissionsTable,
      },
    ],
  },
  feedback: {
    id: 'feedback' as const,
    title: 'Feedback',
    image: 'feedback',
    path: ROUTES.ADMIN.FEEDBACK,
    url: ROUTES.ADMIN.FEEDBACK,
    bg: 'deep-grey',
    globalSetting: GlobalSettings.FeedbackEnabled,
    canView: [PermissionTypes.ViewUserfeedback],
    component: FeedbackTable,
  },
  todo: {
    id: 'todo' as const,
    title: 'To Do',
    image: 'todo',
    bg: 'deep-grey',
    tabs: commonDashboardSubtabs,
  },
  dataRetention: {
    id: 'dataRetention' as const,
    title: 'Data Retention',
    image: 'data-retention',
    path: ROUTES.APPS.DATA_RETENTION.ANY,
    url: ROUTES.APPS.DATA_RETENTION.EMPLOYMENT,
    bg: 'deep-grey',
    tabs: [
      {
        homeSectionId: HomeSectionId.dataRetentionEmploymentPolicies,
        title: 'Employment policies',
        path: ROUTES.APPS.DATA_RETENTION.EMPLOYMENT,
        url: ROUTES.APPS.DATA_RETENTION.EMPLOYMENT,
        canView: [PermissionTypes.ViewDataRetentionPeriod],
        component: EmploymentPoliciesTable,
      },
      {
        homeSectionId: HomeSectionId.dataRetentionRecruitmentPolicies,
        title: 'Recruitment policies',
        path: ROUTES.APPS.DATA_RETENTION.RECRUITMENT,
        url: ROUTES.APPS.DATA_RETENTION.RECRUITMENT,
        canView: [PermissionTypes.ViewDataRetentionPeriod],
        component: RecruitmentPoliciesTable,
      },
    ],
  },
  communication: {
    id: 'communication' as const,
    title: 'Communication',
    image: 'notifications',
    path: ROUTES.APPS.COMMUNICATION.ANY,
    url: ROUTES.APPS.COMMUNICATION.NOTIFICATIONS,
    bg: 'deep-grey',
    settingsConfig: settingsConfig.communication,
    tabs: [
      {
        homeSectionId: HomeSectionId.notifications,
        title: 'Notifications',
        path: ROUTES.APPS.COMMUNICATION.NOTIFICATIONS,
        url: ROUTES.APPS.COMMUNICATION.NOTIFICATIONS,
        canView: [PermissionTypes.ViewTemplatedNotifications],
        component: NotificationTable,
      },
      {
        homeSectionId: HomeSectionId.communicationGroups,
        title: 'Groups',
        homeSectionTitle: 'Communication groups',
        path: ROUTES.APPS.COMMUNICATION.GROUPS,
        url: ROUTES.APPS.COMMUNICATION.GROUPS,
        canView: [PermissionTypes.ViewCommunicationGroup],
        component: CommunicationGroupsTable,
      },
      {
        homeSectionId: HomeSectionId.communicationEmailTemplates,
        title: 'Email templates',
        path: ROUTES.APPS.COMMUNICATION.EMAIL_TEMPLATES,
        url: ROUTES.APPS.COMMUNICATION.EMAIL_TEMPLATES,
        canView: [PermissionTypes.ViewEmployeeEmailTemplate],
        component: EmployeeEmailTemplates,
      },
    ],
  },
  kpis: {
    id: 'kpis' as const,
    title: 'KPIs',
    image: 'goals',
    bg: colors.deepPurple,
    settingsConfig: settingsConfig.kpis,
    tabs: [
      {
        homeSectionId: HomeSectionId.kpis,
        title: 'KPIs',
        path: ROUTES.PERFORMANCE.KPIS,
        url: ROUTES.PERFORMANCE.KPIS,
        canView: [PermissionTypes.ViewKpi],
        component: KPIsTable,
      },
      {
        homeSectionId: HomeSectionId.kpisTemplates,
        title: 'Templates',
        homeSectionTitle: 'KPI templates',
        path: ROUTES.FORMS.KPI_TEMPLATES.TEMPLATES,
        url: ROUTES.FORMS.KPI_TEMPLATES.TEMPLATES,
        canView: [PermissionTypes.ViewKPITemplate],
        component: Templates,
      },
      {
        homeSectionId: HomeSectionId.kpisRules,
        title: 'Rules',
        homeSectionTitle: 'KPI rules',
        path: ROUTES.FORMS.KPI_TEMPLATES.RULES,
        url: ROUTES.FORMS.KPI_TEMPLATES.RULES,
        canView: [PermissionTypes.ViewKPITemplate],
        component: Rules,
      },
    ],
  },
  promotionNominees: {
    id: 'promotionNominees' as const,
    title: 'Promotions',
    image: 'promotions',
    path: ROUTES.PERFORMANCE.PROMOTION_NOMINEES,
    url: ROUTES.PERFORMANCE.PROMOTION_NOMINEES,
    bg: colors.deepPurple,
    globalSetting: GlobalSettings.PromotionsEnabled,
    canView: [PermissionTypes.ViewPromotionNomination],
    component: PromotionNomineesTable,
  },
  skills: {
    id: 'skills' as const,
    title: 'Skills and Values',
    image: 'skills',
    bg: colors.deepPurple,
    settingsConfig: settingsConfig.skills,
    tabs: [
      {
        homeSectionId: HomeSectionId.skillsSkills,
        title: 'Skills',
        path: ROUTES.PERFORMANCE.SKILLS,
        url: ROUTES.PERFORMANCE.SKILLS,
        canView: [PermissionTypes.ViewSkillset],
        component: SkillsTable,
      },
      {
        homeSectionId: HomeSectionId.skillsValues,
        title: 'Values',
        path: ROUTES.PERFORMANCE.VALUES,
        url: ROUTES.PERFORMANCE.VALUES,
        canView: [PermissionTypes.ViewCompanyValue],
        component: CultureValuesTable,
      },
    ],
  },
  roadmaps: {
    id: 'roadmaps' as const,
    title: 'Roadmaps',
    image: 'pipelines',
    url: ROUTES.PERFORMANCE.ROADMAPS,
    path: ROUTES.PERFORMANCE.ROADMAPS,
    bg: colors.deepPurple,
    component: RoadmapsMain,
    settingsConfig: settingsConfig.roadmaps,
  },
  performanceReview: {
    id: 'performanceReview' as const,
    title: 'Performance',
    image: 'performance-reviews',
    bg: colors.deepPurple,
    settingsConfig: settingsConfig.performance,
    tabs: [
      {
        homeSectionId: HomeSectionId.performanceReviewCycles,
        title: 'Performance Reviews',
        homeSectionTitle: 'Performance reviews',
        path: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.ANY,
        url: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.REVIEW_CYCLES,
        canView: [PermissionTypes.ViewReviewCycles],
        subtabs: [
          {
            id: 'review-cycles',
            title: 'Review Cycles',
            path: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.REVIEW_CYCLES,
            to: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.REVIEW_CYCLES,
            component: ReviewCyclesTable,
          },
          {
            id: 'eligible-employees',
            title: 'Eligible employees',
            path: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.ELIGIBLE_EMPLOYEES,
            to: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.ELIGIBLE_EMPLOYEES,
            permission: PermissionTypes.ViewReviewsWhitelist,
            component: SupportToolEligibleEmployees,
          },
          {
            id: 'grade-calibration',
            title: 'Grade calibration',
            path: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.GRADE_CALIBRATION,
            to: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.GRADE_CALIBRATION,
            permission: PermissionTypes.ViewReviewsWhitelist,
            component: SupportToolGradeCalibration,
          },
          {
            id: 'whitelist',
            title: 'Whitelist',
            path: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.WHITELIST,
            to: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.WHITELIST,
            permission: PermissionTypes.ViewReviewsWhitelist,
            component: SupportToolWhitelist,
          },
        ],
      },
      {
        homeSectionId: HomeSectionId.probation,
        title: 'Probation',
        path: ROUTES.PERFORMANCE.PROBATION.ANY,
        url: ROUTES.PERFORMANCE.PROBATION.EMPLOYEES,
        performanceSetting: PerformanceSettings.EnableProbation,
        subtabs: [
          {
            id: 'employees',
            homeSectionId: HomeSectionId.probation,
            title: 'Employees',
            path: ROUTES.PERFORMANCE.PROBATION.EMPLOYEES,
            to: ROUTES.PERFORMANCE.PROBATION.EMPLOYEES,
            component: () => <ProbationEmployeesTable category="probation" />,
          },
          {
            id: 'templates',
            homeSectionId: HomeSectionId.probation,
            title: 'Templates',
            path: ROUTES.PERFORMANCE.PROBATION.TEMPLATES,
            to: ROUTES.PERFORMANCE.PROBATION.TEMPLATES,
            permission: PermissionTypes.ViewProbationEmployees,
            component: ProbationTemplatesTable,
          },
        ],
      },
      {
        homeSectionId: HomeSectionId.pip,
        title: 'PIP',
        path: ROUTES.PERFORMANCE.PIP,
        url: ROUTES.PERFORMANCE.PIP,
        component: () => <ProbationEmployeesTable category="pip" />,
        performanceSetting: PerformanceSettings.EnablePip,
      },
    ],
  },
  employees: {
    id: 'employees' as const,
    title: 'Employees',
    image: 'employees',
    bg: 'yellow',
    settingsConfig: settingsConfig.employees,
    tabs: [
      {
        homeSectionId: HomeSectionId.employees,
        title: 'Employees',
        path: ROUTES.APPS.EMPLOYEES.ALL,
        url: ROUTES.APPS.EMPLOYEES.ALL,
        canView: [PermissionTypes.ViewEmployees],
        component: EmployeeTable,
      },
    ],
  },
  lifecycle: {
    id: 'lifecycle' as const,
    title: 'Lifecycle',
    image: 'onboarding',
    bg: 'yellow',
    path: ROUTES.APPS.LIFECYCLE.ANY,
    url: ROUTES.APPS.LIFECYCLE.ONBOARDING,
    settingsConfig: settingsConfig.lifecycle,
    tabs: [
      {
        homeSectionId: HomeSectionId.onboarding,
        title: 'Onboarding',
        path: ROUTES.APPS.LIFECYCLE.ONBOARDING,
        url: ROUTES.APPS.LIFECYCLE.ONBOARDING,
        canView: [PermissionTypes.ViewEmployeeHROnboardingProcess],
        component: OnboardingEmployeesTable,
      },
      {
        homeSectionId: HomeSectionId.offboarding,
        title: 'Offboarding',
        path: ROUTES.APPS.LIFECYCLE.OFFBOARDING,
        url: ROUTES.APPS.LIFECYCLE.OFFBOARDING,
        canView: [PermissionTypes.ViewOffboardingV2],
        component: OffboardingTable,
      },
      {
        homeSectionId: HomeSectionId.resignations,
        title: 'Resignations',
        path: ROUTES.APPS.LIFECYCLE.RESIGNATIONS,
        url: ROUTES.APPS.LIFECYCLE.RESIGNATIONS,
        canView: [PermissionTypes.ViewResignations],
        globalSetting: GlobalSettings.ResignationsEnabled,
        component: ResignationsTable,
      },
    ],
  },
  timeOff: {
    id: 'timeOff' as const,
    title: 'Time Off',
    image: 'time-off',
    bg: 'yellow',
    path: ROUTES.APPS.TIME_OFF.ANY,
    url: ROUTES.APPS.TIME_OFF.REQUESTS,
    settingsConfig: settingsConfig.timeOff,
    tabs: [
      {
        homeSectionId: HomeSectionId.timeOffRequests,
        title: 'Requests',
        homeSectionTitle: 'Time off requests',
        path: ROUTES.APPS.TIME_OFF.REQUESTS,
        url: ROUTES.APPS.TIME_OFF.REQUESTS,
        canView: [PermissionTypes.ViewTimeOffRequestsOfReports],
        component: Requests,
      },
      {
        homeSectionId: HomeSectionId.timeOffPolicies,
        title: 'Policies',
        homeSectionTitle: 'Time off policies',
        path: ROUTES.APPS.TIME_OFF.POLICIES,
        url: ROUTES.APPS.TIME_OFF.POLICIES,
        canView: [PermissionTypes.ViewTimeOffPolicies],
        component: Policies,
      },
    ],
  },
  contracts: {
    id: 'contracts' as const,
    title: 'Contracts',
    image: 'contracts',
    bg: 'yellow',
    path: ROUTES.APPS.CONTRACTS.ANY,
    url: ROUTES.APPS.CONTRACTS.ALL,
    settingsConfig: settingsConfig.contracts,
    tabs: [
      {
        homeSectionId: HomeSectionId.contracts,
        title: 'Contracts',
        path: ROUTES.APPS.CONTRACTS.ALL,
        url: ROUTES.APPS.CONTRACTS.ALL,
        canView: [PermissionTypes.ViewContractsTab],
        component: ContractsTable,
      },
      // TODO: temporarily hidden, uncomment when BE is ready - https://revolut.atlassian.net/browse/RHR-4806
      // {
      //   homeSectionId: HomeSectionId.contractTypes,
      //   title: 'Types',
      //   homeSectionTitle: 'Contract types',
      //   path: ROUTES.APPS.CONTRACTS.TYPES,
      //   url: ROUTES.APPS.CONTRACTS.TYPES,
      //   canView: [PermissionTypes.ViewContractType],
      //   component: ContractTypes,
      // },
    ],
  },
  payroll: {
    id: 'payroll' as const,
    title: 'Payroll',
    image: 'payroll',
    bg: 'yellow',
    path: ROUTES.APPS.PAYROLL.PAY_CYCLES_TABLE,
    url: ROUTES.APPS.PAYROLL.PAY_CYCLES_TABLE,
    globalSetting: GlobalSettings.PayrollEnabled,
    settingsConfig: settingsConfig.payroll,
    tabs: [
      {
        homeSectionId: HomeSectionId.payrollPayCycles,
        title: 'Pay cycles',
        path: ROUTES.APPS.PAYROLL.PAY_CYCLES_TABLE,
        url: ROUTES.APPS.PAYROLL.PAY_CYCLES_TABLE,
        canView: [PermissionTypes.ViewPaygroup],
        component: PayCyclesTable,
      },
      {
        homeSectionId: HomeSectionId.payrollPayments,
        title: 'Payments',
        path: ROUTES.APPS.PAYROLL.PAYMENTS_TABLE,
        url: ROUTES.APPS.PAYROLL.PAYMENTS_TABLE,
        canView: [PermissionTypes.ViewPaygroup],
        globalSetting: GlobalSettings.CommercialProductDisabled,
        component: PaymentsTable,
      },
    ],
  },
  attendance: {
    id: 'attendance' as const,
    title: 'Attendance',
    image: 'attendance',
    bg: 'yellow',
    path: ROUTES.APPS.ATTENDANCE.ANY,
    url: ROUTES.APPS.ATTENDANCE.SCHEDULER,
    settingsConfig: settingsConfig.attendance,
    globalSetting: GlobalSettings.AttendanceEnabled,
    tabs: [
      {
        homeSectionId: HomeSectionId.attendanceSchedule,
        title: 'Schedule',
        path: ROUTES.APPS.ATTENDANCE.SCHEDULER,
        url: ROUTES.APPS.ATTENDANCE.SCHEDULER,
        canView: [PermissionTypes.ViewDirectReportsSchedule],
        component: Scheduler,
      },
      {
        homeSectionId: HomeSectionId.attendanceShifts,
        title: 'Shifts',
        path: ROUTES.APPS.ATTENDANCE.SHIFT_SUMMARY,
        url: ROUTES.APPS.ATTENDANCE.SHIFT_SUMMARY,
        canView: [PermissionTypes.ViewShiftsSummary],
        component: ShiftSummary,
      },
      {
        homeSectionId: HomeSectionId.attendanceCompensation,
        title: 'Compensation',
        homeSectionTitle: 'Attendance compensation',
        path: ROUTES.APPS.ATTENDANCE.SHIFT_BREAKDOWN,
        url: ROUTES.APPS.ATTENDANCE.SHIFT_BREAKDOWN,
        canView: [PermissionTypes.ViewShiftsBreakdown],
        component: ShiftBreakdown,
      },
      {
        homeSectionId: HomeSectionId.attendancePolicies,
        title: 'Policies',
        homeSectionTitle: 'Attendance policies',
        path: ROUTES.APPS.ATTENDANCE.POLICIES,
        url: ROUTES.APPS.ATTENDANCE.POLICIES,
        canView: [PermissionTypes.ViewSchedulingPolicy],
        component: AttendancePolicies,
      },
    ],
  },
  compensation: {
    id: 'compensation' as const,
    title: 'Compensation',
    image: 'compensation',
    path: ROUTES.APPS.COMPENSATION.ANY,
    url: ROUTES.APPS.COMPENSATION.BANDS,
    bg: 'yellow',
    globalSetting: GlobalSettings.TotalCompensationEnabled,
    tabs: [
      {
        homeSectionId: HomeSectionId.compensationBands,
        title: 'Bands',
        path: ROUTES.APPS.COMPENSATION.BANDS,
        url: ROUTES.APPS.COMPENSATION.BANDS,
        canView: [PermissionTypes.ViewBenchmarks],
        component: Benchmarks,
      },
      {
        homeSectionId: HomeSectionId.compensationBonuses,
        title: 'Bonuses',
        path: ROUTES.APPS.COMPENSATION.BONUSES,
        url: ROUTES.APPS.COMPENSATION.BONUSES,
        canView: [PermissionTypes.ViewEmployeeBonusBulkUploadSession],
        component: Bonuses,
      },
    ],
  },
  documents: {
    id: 'documents' as const,
    title: 'Documents',
    image: 'documents',
    bg: 'yellow',
    settingsConfig: settingsConfig.documents,
    tabs: [
      {
        homeSectionId: HomeSectionId.documentsAll,
        path: ROUTES.APPS.DOCUMENTS.DOCUMENTS,
        url: ROUTES.APPS.DOCUMENTS.DOCUMENTS,
        title: 'Documents',
        homeSectionTitle: 'All documents',
        canView: [PermissionTypes.ViewEmployeeDocuments],
        component: AllDocuments,
      },
      {
        homeSectionId: HomeSectionId.documentCategories,
        path: ROUTES.APPS.DOCUMENTS.CATEGORIES,
        url: ROUTES.APPS.DOCUMENTS.CATEGORIES,
        title: 'Categories',
        homeSectionTitle: 'Document categories',
        canView: [PermissionTypes.ViewDocumentCategories],
        component: DocumentCategories,
      },
      {
        homeSectionId: HomeSectionId.documentTemplates,
        path: ROUTES.APPS.DOCUMENTS.TEMPLATES.LIST,
        url: ROUTES.APPS.DOCUMENTS.TEMPLATES.LIST,
        title: 'Templates',
        homeSectionTitle: 'Document templates',
        canView: [PermissionTypes.ViewDocumenttemplates],
        component: DocumentTemplates,
      },
      {
        homeSectionId: HomeSectionId.documentCollections,
        path: ROUTES.APPS.DOCUMENTS.COLLECTIONS,
        url: ROUTES.APPS.DOCUMENTS.COLLECTIONS,
        title: 'Collections',
        homeSectionTitle: 'Document collections',
        canView: [PermissionTypes.ViewDocumenttemplates],
        globalSetting: GlobalSettings.DocumentsTemplatesEnabled,
        component: DocumentsCollections,
      },
      {
        homeSectionId: HomeSectionId.documentRules,
        path: ROUTES.APPS.DOCUMENTS.RULES,
        url: ROUTES.APPS.DOCUMENTS.RULES,
        title: 'Rules',
        homeSectionTitle: 'Document rules',
        canView: [PermissionTypes.ViewDocumenttemplaterules],
        component: DocumentRules,
      },
      {
        homeSectionId: HomeSectionId.documentBulkRequests,
        path: ROUTES.APPS.DOCUMENTS.BULK_REQUEST.LIST,
        url: ROUTES.APPS.DOCUMENTS.BULK_REQUEST.LIST,
        title: 'Requests',
        homeSectionTitle: 'Document bulk requests',
        canView: [PermissionTypes.ViewDocumentBulkRequest],
        featureFlags: [FeatureFlags.BulkRequestDocuments],
        component: DocumentsBulkRequests,
      },
    ],
  },
  benefits: {
    id: 'benefits' as const,
    title: 'Benefits',
    image: 'benefits',
    bg: 'yellow',
    globalSetting: GlobalSettings.BenefitsEnabled,
    tabs: [
      {
        homeSectionId: HomeSectionId.benefitEnrolment,
        title: 'Enrolment',
        homeSectionTitle: 'Benefit enrolment',
        path: ROUTES.APPS.BENEFITS.ENROLMENT,
        url: ROUTES.APPS.BENEFITS.ENROLMENT,
        canView: [PermissionTypes.ViewBenefitsTemplate],
        component: BenefitEnrolment,
      },
      {
        homeSectionId: HomeSectionId.benefitTemplates,
        title: 'Templates',
        homeSectionTitle: 'Benefit templates',
        path: ROUTES.APPS.BENEFITS.TEMPLATES,
        url: ROUTES.APPS.BENEFITS.TEMPLATES,
        canView: [PermissionTypes.ViewBenefitsTemplate],
        component: BenefitTemplates,
      },
    ],
  },
  engagement: {
    id: 'engagement' as const,
    title: 'Engagement',
    image: 'engagement',
    bg: 'yellow',
    path: ROUTES.APPS.ENGAGEMENT.ANY,
    url: ROUTES.APPS.ENGAGEMENT.DRIVERS_TABLE,
    globalSetting: GlobalSettings.EngagementEnabled,
    settingsConfig: settingsConfig.engagement,
    tabs: [
      {
        homeSectionId: HomeSectionId.engagementSurveys,
        title: 'Surveys',
        path: ROUTES.APPS.ENGAGEMENT.SURVEYS_TABLE,
        url: ROUTES.APPS.ENGAGEMENT.SURVEYS_TABLE,
        canView: [PermissionTypes.ViewEngagement],
        component: SurveysTable,
      },
      {
        homeSectionId: HomeSectionId.engagementDrivers,
        title: 'Drivers',
        path: ROUTES.APPS.ENGAGEMENT.DRIVERS_TABLE,
        url: ROUTES.APPS.ENGAGEMENT.DRIVERS_TABLE,
        canView: [PermissionTypes.ViewEngagement],
        component: DriversTable,
      },
      {
        homeSectionId: HomeSectionId.engagementQuestions,
        title: 'Questions',
        path: ROUTES.APPS.ENGAGEMENT.QUESTIONS_TABLE,
        url: ROUTES.APPS.ENGAGEMENT.QUESTIONS_TABLE,
        canView: [PermissionTypes.ViewEngagement],
        component: QuestionsTable,
      },
    ],
  },
  keyPersons: {
    id: 'keyPersons' as const,
    title: 'Key Persons',
    image: 'succession',
    bg: 'yellow',
    path: ROUTES.APPS.KEY_PERSONS.ANY,
    url: ROUTES.APPS.KEY_PERSONS.RESPONSIBILITIES_ASSIGNMENTS_TABLE,
    globalSetting: GlobalSettings.CommercialProductDisabled,
    settingsConfig: settingsConfig.keyPersons,
    tabs: [
      {
        homeSectionId: HomeSectionId.keyPersonsRiskProfiles,
        title: 'Assignments',
        path: ROUTES.APPS.KEY_PERSONS.RESPONSIBILITIES_ASSIGNMENTS_TABLE,
        url: ROUTES.APPS.KEY_PERSONS.RESPONSIBILITIES_ASSIGNMENTS_TABLE,
        component: KeyPersonsResponsibilityAssignmentsTable,
        canView: [PermissionTypes.ViewKeyRiskProfile],
      },
      {
        homeSectionId: HomeSectionId.keyPersonsResponsibilities,
        title: 'Responsibilities',
        path: ROUTES.APPS.KEY_PERSONS.RESPONSIBILITIES_TABLE,
        url: ROUTES.APPS.KEY_PERSONS.RESPONSIBILITIES_TABLE,
        component: KeyPersonsResponsibilitiesTable,
        canView: [PermissionTypes.ViewKeyPersonType],
      },
      {
        homeSectionId: HomeSectionId.keyPersonsSuccessionPlans,
        title: 'Succession',
        path: ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLANS_TABLE,
        url: ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLANS_TABLE,
        component: KeyPersonsSuccessionPlansTable,
        canView: [PermissionTypes.ViewKeyRiskProfileSuccessionPlans],
      },
    ],
  },
  requisitions: {
    id: 'requisitions' as const,
    title: 'Requisitions',
    image: 'requisitions',
    path: ROUTES.APPS.REQUISITIONS,
    url: ROUTES.APPS.REQUISITIONS,
    bg: colors.teal,
    canView: [PermissionTypes.ViewRequisitions],
    globalSetting: GlobalSettings.RequisitionsEnabled,
    component: RequisitionsTable,
    settingsConfig: settingsConfig.requisitions,
  },
  jobPostings: {
    id: 'jobPostings' as const,
    title: 'Job postings',
    image: 'job-postings',
    path: ROUTES.APPS.JOB_POSTINGS,
    url: ROUTES.APPS.JOB_POSTINGS,
    bg: colors.teal,
    canView: [PermissionTypes.ViewJobdescriptions],
    globalSetting: GlobalSettings.JobPostingsEnabled,
    component: JobPostingsTable,
    settingsConfig: settingsConfig.jobPostings,
  },
  hiringPipelines: {
    id: 'hiringPipelines' as const,
    title: 'Pipelines',
    image: 'hiring-pipelines',
    path: ROUTES.APPS.HIRING_PIPELINES.ANY,
    url: ROUTES.APPS.HIRING_PIPELINES.ALL,
    bg: colors.teal,
    canView: [PermissionTypes.ViewHiringPipeline],
    globalSetting: GlobalSettings.ApplicantsTrackingEnabled,
  },
  candidates: {
    id: 'candidates' as const,
    title: 'Candidates',
    image: 'candidates',
    bg: colors.teal,
    globalSetting: GlobalSettings.ApplicantsTrackingEnabled,
    settingsConfig: settingsConfig.candidates,
    tabs: [
      {
        homeSectionId: HomeSectionId.candidates,
        title: 'Candidates',
        path: ROUTES.APPS.CANDIDATES.CANDIDATES,
        url: ROUTES.APPS.CANDIDATES.CANDIDATES,
        canView: [PermissionTypes.ViewCandidate],
        component: () => <CommonCandidatesTable type="common" />,
      },
      {
        homeSectionId: HomeSectionId.interviews,
        title: 'Interviews',
        path: ROUTES.APPS.CANDIDATES.INTERVIEWS.ANY,
        url: ROUTES.APPS.CANDIDATES.INTERVIEWS.PENDING,
        canView: [PermissionTypes.ViewInterviewFeedback],
        component: Interviews,
      },
      {
        homeSectionId: HomeSectionId.candidatesDataRetentionSettings,
        title: 'Data retention settings',
        path: ROUTES.APPS.CANDIDATES.DATA_RETENTION_SETTINGS,
        url: ROUTES.APPS.CANDIDATES.DATA_RETENTION_SETTINGS,
        canView: [PermissionTypes.ViewDataRetentionPeriod],
        component: DataRetentionSettingsTable,
      },
      {
        homeSectionId: HomeSectionId.candidatesEmailTemplates,
        title: 'Email templates',
        homeSectionTitle: 'Candidate email templates',
        path: ROUTES.APPS.CANDIDATES.EMAIL_TEMPLATES,
        url: ROUTES.APPS.CANDIDATES.EMAIL_TEMPLATES,
        canView: [PermissionTypes.ViewEmailTemplate],
        component: EmailTemplatesTable,
      },
      {
        homeSectionId: HomeSectionId.candidatesReferralBonus,
        title: 'Referral bonus',
        path: ROUTES.APPS.CANDIDATES.REFERRAL_BONUSES,
        url: ROUTES.APPS.CANDIDATES.REFERRAL_BONUSES,
        canView: [PermissionTypes.ViewReferralBonus],
        component: ReferralBonusTable,
        globalSetting: GlobalSettings.ReferralsEnabled,
      },
    ],
  },
  hiringProcess: {
    id: 'hiringProcess' as const,
    title: 'Hiring process',
    image: 'hiring-process',
    bg: colors.teal,
    globalSetting: GlobalSettings.ApplicantsTrackingEnabled,
    settingsConfig: settingsConfig.hiringProcess,
    tabs: [
      {
        homeSectionId: HomeSectionId.hiringStages,
        title: 'Hiring stages',
        path: ROUTES.APPS.HIRING_PROCESS.STAGES,
        url: ROUTES.APPS.HIRING_PROCESS.STAGES,
        canView: [PermissionTypes.ViewHiringstage],
        component: HiringTable,
      },
      {
        homeSectionId: HomeSectionId.hiringProcessRecruitmentGroups,
        title: 'Recruitment groups',
        path: ROUTES.APPS.HIRING_PROCESS.RECRUITMENT_GROUPS,
        url: ROUTES.APPS.HIRING_PROCESS.RECRUITMENT_GROUPS,
        canView: [PermissionTypes.ViewRecruitmentgroups],
        component: RecruitmentGroupsTable,
        globalSetting: GlobalSettings.RecruitmentGroupsEnabled,
      },
      {
        homeSectionId: HomeSectionId.interviewScorecardTemplates,
        title: 'Interview scorecard templates',
        path: ROUTES.APPS.HIRING_PROCESS.SCORECARDS,
        url: ROUTES.APPS.HIRING_PROCESS.SCORECARDS,
        canView: [PermissionTypes.ViewInterviewScorecardTemplate],
        component: InterviewScorecardTemplatesTable,
      },
    ],
  },
  offers: {
    id: 'offers' as const,
    title: 'Offers',
    image: 'offers',
    bg: colors.teal,
    globalSetting: GlobalSettings.OffersTemplatesEnabled,
    tabs: [
      {
        homeSectionId: HomeSectionId.offersAll,
        title: 'Offers',
        path: ROUTES.APPS.OFFERS.OFFERS,
        url: ROUTES.APPS.OFFERS.OFFERS,
        canView: [PermissionTypes.ViewOfferForm],
        component: OffersTable,
      },
      {
        homeSectionId: HomeSectionId.offersPlaceholders,
        path: ROUTES.APPS.OFFERS.OFFER_PLACEHOLDERS,
        url: ROUTES.APPS.OFFERS.OFFER_PLACEHOLDERS,
        title: 'Placeholders',
        canView: [PermissionTypes.ViewOfferFormTemplate],
        component: OfferPlaceholders,
      },
      {
        homeSectionId: HomeSectionId.offersTemplates,
        path: ROUTES.APPS.OFFERS.OFFER_TEMPLATES,
        url: ROUTES.APPS.OFFERS.OFFER_TEMPLATES,
        title: 'Templates',
        canView: [PermissionTypes.ViewOfferFormTemplate],
        component: OfferTemplates,
      },
    ],
  },
  teams: {
    id: 'teams' as const,
    title: 'Teams',
    image: 'teams',
    bg: colors.blue,
    settingsConfig: settingsConfig.teams,
    tabs: [
      {
        homeSectionId: HomeSectionId.teams,
        title: 'Teams',
        path: ROUTES.APPS.TEAMS.TEAMS,
        url: ROUTES.APPS.TEAMS.TEAMS,
        canView: [PermissionTypes.ViewTeams],
        component: TeamsTable,
      },
      {
        homeSectionId: HomeSectionId.departments,
        title: 'Departments',
        path: ROUTES.APPS.TEAMS.DEPARTMENTS,
        url: ROUTES.APPS.TEAMS.DEPARTMENTS,
        canView: [PermissionTypes.ViewDepartment],
        component: DepartmentsTable,
      },
      {
        homeSectionId: HomeSectionId.company,
        title: 'Company',
        path: ROUTES.FORMS.COMPANY.KPI.GOALS,
        url: ROUTES.FORMS.COMPANY.KPI.GOALS,
        // usually we don't want to push tabs routes to the history, so back button leads to hub apps landing
        // but for company we navigate to the organisation layout with own tabs and want back button to return to teams app
        urlDescriptor: (location: Location<{ history: string[] }>) => {
          return {
            pathname: ROUTES.FORMS.COMPANY.KPI.GOALS,
            state: {
              ...location.state,
              history: location.state.history
                ? [...location.state.history, location.pathname]
                : [location.pathname],
            },
          }
        },
      },
    ],
  },
  positions: {
    id: 'positions' as const,
    title: 'Roles',
    image: 'positions',
    bg: colors.blue,
    settingsConfig: settingsConfig.roles,
    tabs: [
      {
        homeSectionId: HomeSectionId.specialisations,
        title: 'Specialisations',
        path: ROUTES.APPS.POSITIONS.SPECIALISATIONS,
        url: ROUTES.APPS.POSITIONS.SPECIALISATIONS,
        canView: [PermissionTypes.ViewRoles],
        component: SpecialisationsTable,
      },
      {
        homeSectionId: HomeSectionId.roles,
        title: 'Roles',
        path: ROUTES.APPS.POSITIONS.ROLES,
        url: ROUTES.APPS.POSITIONS.ROLES,
        canView: [PermissionTypes.ViewRoles],
        component: RolesTable,
      },
      {
        homeSectionId: HomeSectionId.functions,
        title: 'Functions',
        path: ROUTES.APPS.POSITIONS.FUNCTIONS,
        url: ROUTES.APPS.POSITIONS.FUNCTIONS,
        canView: [PermissionTypes.ViewFunction],
        component: FunctionsTable,
      },
      {
        homeSectionId: HomeSectionId.employeesSeniorities,
        title: 'Seniorities',
        path: ROUTES.APPS.POSITIONS.SENIORITIES,
        url: ROUTES.APPS.POSITIONS.SENIORITIES,
        canView: [PermissionTypes.ViewSeniority],
        component: SenioritiesTable,
      },
    ],
  },
  locations: {
    id: 'locations' as const,
    title: 'Locations',
    image: 'locations',
    path: ROUTES.APPS.LOCATIONS,
    url: ROUTES.APPS.LOCATIONS,
    bg: colors.blue,
    canView: [PermissionTypes.ViewLocations],
    component: LocationsTable,
    settingsConfig: settingsConfig.locations,
  },
  entities: {
    id: 'entities' as const,
    title: 'Legal Entities',
    image: 'legal-entities',
    path: ROUTES.APPS.ENTITIES,
    url: ROUTES.APPS.ENTITIES,
    bg: colors.blue,
    canView: [PermissionTypes.ViewEntity],
    component: EntitiesTable,
    settingsConfig: settingsConfig.legalEntities,
  },
  screening: {
    id: 'screening' as const,
    title: 'Screening',
    image: 'screening',
    bg: 'yellow',
    path: ROUTES.APPS.SCREENING.SCREENING_TABLE,
    url: ROUTES.APPS.SCREENING.SCREENING_TABLE,
    globalSetting: GlobalSettings.ScreeningEnabled,
    canView: [PermissionTypes.ViewAllScreeningChecks],
    component: ScreeningsTable,
    settingsConfig: settingsConfig.screening,
  },
  groups: {
    id: 'groups' as const,
    title: 'Groups',
    image: 'groups',
    bg: 'deep-grey',
    path: ROUTES.APPS.GROUPS,
    url: ROUTES.APPS.GROUPS,
    canView: [PermissionTypes.ViewDynamicGroups],
    component: DynamicGroupsTable,
    settingsConfig: settingsConfig.groups,
  },
  careerPageSettings: {
    id: 'careerPageSettings' as const,
    title: 'Careers Page',
    image: 'careers-page',
    bg: colors.teal,
    path: ROUTES.SETTINGS.CAREER_PAGE,
    url: ROUTES.SETTINGS.CAREER_PAGE,
    canView: [PermissionTypes.ViewCareerPagePreferences],
    globalSetting: GlobalSettings.CareerPageEnabled,
    settingsConfig: settingsConfig.careersPage,
  },
  reportingApp: {
    id: 'reportingApp' as const,
    title: 'Reporting',
    image: 'analytics',
    bg: colors.teal,
    tabs: [
      {
        homeSectionId: HomeSectionId.reportingAppReports,
        title: 'Reports',
        path: ROUTES.APPS.DATA_ANALYTICS.REPORTS,
        url: ROUTES.APPS.DATA_ANALYTICS.REPORTS,
        canView: [PermissionTypes.UseReportingApp],
        component: ReportsTable,
      },
      {
        homeSectionId: HomeSectionId.reportingAppQueries,
        title: 'Queries',
        path: ROUTES.APPS.DATA_ANALYTICS.QUERIES,
        url: ROUTES.APPS.DATA_ANALYTICS.QUERIES,
        canView: [PermissionTypes.UseReportingApp],
        component: QueriesTable,
      },
      {
        homeSectionId: HomeSectionId.reportingAppConnections,
        title: 'Connections',
        path: ROUTES.APPS.DATA_ANALYTICS.CONNECTIONS,
        url: ROUTES.APPS.DATA_ANALYTICS.CONNECTIONS,
        canView: [PermissionTypes.UseReportingApp],
        component: ConnectionsTable,
      },
    ],
  },
  helpCenter: {
    id: 'helpCenter' as const,
    title: 'Help Centre',
    image: 'help-center',
    bg: 'deep-grey',
    path: ROUTES.APPS.HELP_CENTER.ANY,
    url: ROUTES.APPS.HELP_CENTER.FAQ_TABLE,
    globalSetting: GlobalSettings.CommercialProductDisabled,
    settingsConfig: settingsConfig.helpCentre,
    tabs: [
      {
        homeSectionId: HomeSectionId.supportFaq,
        title: 'FAQs',
        path: ROUTES.APPS.HELP_CENTER.FAQ_TABLE,
        url: ROUTES.APPS.HELP_CENTER.FAQ_TABLE,
        component: HelpCenterFaqTable,
        canView: [PermissionTypes.ViewFaq],
      },
      {
        homeSectionId: HomeSectionId.supportFaqTopics,
        title: 'FAQ Topics',
        path: ROUTES.APPS.HELP_CENTER.FAQ_TOPICS_TABLE,
        url: ROUTES.APPS.HELP_CENTER.FAQ_TOPICS_TABLE,
        component: HelpCenterFaqTopicsTable,
        canView: [PermissionTypes.ViewFaqTopic],
      },
      {
        title: 'SD Categories',
        path: ROUTES.APPS.HELP_CENTER.SD_CATEGORIES_TABLE,
        url: ROUTES.APPS.HELP_CENTER.SD_CATEGORIES_TABLE,
        component: FaqSDCategoriesTable,
        canView: [PermissionTypes.ViewFaq],
      },
    ],
  },
}

export const APPLICATIONS = createApplicationsMap(appConfig)

export const PINNABLE_APPLICATION_TABS = Object.values(APPLICATIONS).reduce(
  (tabs, app) => {
    if ('tabs' in app) {
      return [
        ...tabs,
        ...Object.values(app.tabs)
          .filter(
            tab => 'homeSectionId' in tab && ('component' in tab || 'subtabs' in tab),
          )
          .map(tab => ({ ...tab, category: app.title })),
      ]
    }
    return tabs
  },
  [] as HubAppTabInterface[],
)

export const globalSettingsAppTabsFilter = (
  id: HomeSectionId,
  settings: {
    employeeSettings?: EmployeeSettingsInterface
    performanceSettings?: PerformanceSettingsInterface
    documentsSettings?: DocumentsSettingsInterface
  },
) => {
  if (id === HomeSectionId.documentTemplates || id === HomeSectionId.documentRules) {
    return !!settings.documentsSettings?.enable_document_generation_from_templates
  }

  if (id === HomeSectionId.employeesCustomFields) {
    return !!settings.employeeSettings?.enable_custom_fields
  }

  if (id === HomeSectionId.kpisTemplates || id === HomeSectionId.kpisRules) {
    return !!settings.performanceSettings?.allowed_kpi_types.find(
      type => type.id === 'template',
    )
  }

  return true
}

export const HUB_APPLICATIONS: HubInterface = [
  {
    id: 'people',
    title: 'People',
    items: [
      APPLICATIONS.employees,
      APPLICATIONS.lifecycle,
      APPLICATIONS.timeOff,
      APPLICATIONS.contracts,
      APPLICATIONS.payroll,
      APPLICATIONS.attendance,
      APPLICATIONS.documents,
      APPLICATIONS.compensation,
      APPLICATIONS.benefits,
      APPLICATIONS.engagement,
      APPLICATIONS.screening,
      APPLICATIONS.keyPersons,
    ],
  },
  {
    id: 'recruitment',
    title: 'Recruitment',
    items: [
      APPLICATIONS.requisitions,
      APPLICATIONS.jobPostings,
      APPLICATIONS.hiringPipelines,
      APPLICATIONS.candidates,
      APPLICATIONS.hiringProcess,
      APPLICATIONS.offers,
    ],
  },
  {
    id: 'organisation',
    title: 'Organisation',
    items: [
      APPLICATIONS.teams,
      APPLICATIONS.positions,
      APPLICATIONS.locations,
      APPLICATIONS.entities,
    ],
  },
  {
    id: 'performance',
    title: 'Performance',
    items: [
      APPLICATIONS.performanceReview,
      APPLICATIONS.kpis,
      APPLICATIONS.roadmaps,
      APPLICATIONS.skills,
      APPLICATIONS.promotionNominees,
    ],
  },
  {
    id: 'general',
    title: 'General',
    items: [
      APPLICATIONS.accessManagement,
      APPLICATIONS.dataRetention,
      APPLICATIONS.communication,
      APPLICATIONS.feedback,
      APPLICATIONS.todo,
      APPLICATIONS.groups,
      APPLICATIONS.reportingApp,
      APPLICATIONS.helpCenter,
    ],
  },
]

export const HUB_INTEGRATIONS = [
  {
    id: 'slack',
    title: 'Slack',
    image: 'slack',
    url: ROUTES.HUB.INTEGRATION.SLACK.ALL,
    canView: [PermissionTypes.ViewSlackIntegration],
  },
  {
    id: 'jira',
    title: 'Jira',
    image: 'jira',
    url: ROUTES.HUB.INTEGRATION.JIRA,
    canView: [PermissionTypes.ViewJiraIntegration],
  },
  {
    id: 'calendar',
    title: 'Google Calendar',
    image: 'calendar',
    url: ROUTES.HUB.INTEGRATION.CALENDAR,
    canView: [PermissionTypes.ViewGoogleCalendarIntegration],
  },
  {
    id: 'docusign',
    title: 'DocuSign',
    image: 'docusign',
    url: ROUTES.HUB.INTEGRATION.DOCUSIGN,
    canView: [PermissionTypes.ViewDocuSignIntegration],
  },
]
